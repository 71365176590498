import React, { useState } from "react";
import Switch from "./switch";
import { putFetcher } from "../fetcher";
import { useTranslation } from "react-i18next";

export const Settings = (props) => {
  console.log("ems_settings", props);
  const { t } = useTranslation("global");
  const [exportLimitation, setExportLimitation] = useState(
    props.device.config.limitation.enabled
  );
  const [exportLimitationValue, setExportLimitationValue] = useState(
    props.device.config.limitation.price
  );

  const [exportGridLimit, setExportGridLimit] = useState(
    props.device.config.limitation.export_grid_limit
  );

  const handleExportLimitation = () => {
    console.log("Export limitation: " + !exportLimitation);
    setExportLimitation(!exportLimitation);
  };

  const handleExportLimitationValue = (e) => {
    console.log("Export limitation value: " + e.target.value);
    setExportLimitationValue(e.target.value);
  };

  const handleExportGridLimit = (e) => {
    console.log("Export grid limit: " + e.target.value);
    setExportGridLimit(e.target.value);
  };

  const handleSave = () => {
    putFetcher(
      "/device/config/" + props.device._id,
      {
        config: {
          limitation: {
            enabled: exportLimitation,
            price: exportLimitationValue,
            export_grid_limit: exportGridLimit,
          },
        },
      },
      props.token
    );

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <>
      <div className="container-fluid">
        <h1 className="h3 mb-2 text-gray-800">{t("ems_settings.settings")}</h1>
        <div class="accordion" id="accordionPanelsStayOpenExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne">
                {t("ems_settings.spot_market_settings") + " "}
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="panelsStayOpen-headingOne">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        {t("ems_settings.enable_export_limitation") + " "}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-2">
                    <Switch
                      isToggled={exportLimitation}
                      onToggle={handleExportLimitation}
                    />
                  </div>
                </div>
                <div className="row">
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">
                      {t("ems_settings.export_limitation_value")}
                    </span>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Export limitation"
                      aria-label="Export limitation"
                      aria-describedby="basic-addon2"
                      value={exportLimitationValue}
                      onChange={handleExportLimitationValue}
                    />
                    <span class="input-group-text" id="basic-addon2">
                      €/MWh
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">
                      {t("ems_settings.grid_export_limit") + " (%) "}
                    </span>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Export limitation"
                      aria-label="Export limitation"
                      aria-describedby="basic-addon2"
                      value={exportGridLimit}
                      onChange={handleExportGridLimit}
                    />
                    <span class="input-group-text" id="basic-addon2">
                      %
                    </span>
                  </div>
                </div>
                {/*save button*/}
                <div className="row">
                  <div className="col-md-1 ms-auto">
                    <button
                      type="button"
                      class="btn btn-success"
                      onClick={handleSave}>
                      {t("ems_settings.save")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo">
                Boiler settings
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseTwo"
              class="accordion-collapse collapse">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        Enable Boiler control
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-2">
                    <Switch
                      isToggled={enableBoiler}
                      onToggle={handleEnableBoiler}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        Enable HDO switch
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-2">
                    <Switch isToggled={enableHDO} onToggle={handleEnableHDO} />
                  </div>
                </div>
                <div className="row">
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">
                      Minimal temperature
                    </span>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Minimal temperature"
                      aria-label="Minimal temperature"
                      aria-describedby="basic-addon2"
                      value={minTemp}
                      onChange={handleMinTemp}
                    />
                    <span class="input-group-text" id="basic-addon2">
                      °C
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">
                      Maximal temperature
                    </span>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Maximal temperature"
                      aria-label="Maximal temperature"
                      aria-describedby="basic-addon2"
                      value={maxTemp}
                      onChange={handleMaxTemp}
                    />
                    <span class="input-group-text" id="basic-addon2">
                      °C
                    </span>
                  </div>
                </div>
                {/*save button}
                <div className="row">
                  <div className="col-md-4">
                    <button type="button" class="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          */}
        </div>
      </div>
    </>
  );
};
