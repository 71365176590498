import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getFetcher } from "../components/fetcher";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/AuthProvider";

const Installations = () => {
  const { t } = useTranslation("global");
  const context = useContext(AuthContext);
  const [installations, setInstallations] = useState([]);
  const [loading, setLoading] = useState(true);

  const getInstallations = () => {
    getFetcher("/installations").then((data) => {
      setInstallations(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getInstallations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className="container">
        <h1 className="text-white text-center pt-5">{t("general.loading")}</h1>
      </div>
    );
  } else {
    if (installations.length === 0) {
      return (
        <div className="container">
          <h1 className="text-white text-center pt-5">
            {t("No installations")}
          </h1>
        </div>
      );
    } else {
      return (
        <div className="container">
          <h1 className="text-white text-center pt-5">
            {t("installations.title")}
          </h1>
          {context.user.role.includes("admin") ||
          context.user.role.includes("intaller") ? (
            <div className="">
              <Link to="/add_installation" className="btn btn-primary">
                {t("Add installation")}
              </Link>
            </div>
          ) : null}
          <div className="row no-gutters p-3">
            <div className="col">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">{t("Name")}</th>
                    <th scope="col">{t("owner")}</th>
                    <th scope="col">{t("installer")}</th>
                    <th scope="col">{t("Country")}</th>
                    <th scope="col">{t("Postal code")}</th>
                    <th scope="col">{t("Actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {installations.map((installation) => {
                    return (
                      <tr key={installation.id}>
                        <td>{installation.name}</td>
                        <td>
                          {installation.owner.name +
                            " " +
                            installation.owner.lastname}
                        </td>
                        <td>
                          {installation.installer.name +
                            " " +
                            installation.installer.lastname}
                        </td>
                        <td>{installation.country}</td>
                        <td>{installation.postal_code}</td>
                        <td>
                          <Link
                            to={"/installation/" + installation.id}
                            className="btn btn-primary">
                            {t("View")}
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    }
  }
};

export default Installations;
