import React, { useEffect, useState } from "react";
import { getFetcher } from "../fetcher";
import { useTranslation } from "react-i18next";

export const SLCLog = (props) => {
  const { t } = useTranslation("global");

  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const getLogs = () => {
    getFetcher("/log/device/" + props.id).then((data) => {
      setLogs(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  } else {
    if (logs.length === 0) {
      return <div>{t("No logs")}</div>;
    } else {
      return (
        <div className="row no-gutters p-3">
          <div className="col">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{t("Timestamp")}</th>
                  <th scope="col">{t("Message")}</th>
                  <th scope="col">{t("User")}</th>
                </tr>
              </thead>
              <tbody>
                {logs.map((log) => {
                  return (
                    <tr key={log.sys.cts}>
                      <td>{log.sys.cts}</td>
                      <td>{log.message}</td>
                      <td>
                        {log.user.name} {log.user.lastname}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
};
